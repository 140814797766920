const theme = {
  font: {
    primary: `Poppins, sans-serif; font-weight: 400;`,
    secondary: `Poppins, sans-serif; font-weight: 400;`,
    extraLight: `Poppins, sans-serif; font-weight: 200;`,
    light: `Poppins, sans-serif; font-weight: 300;`,
    regular: `Poppins, sans-serif; font-weight: 400;`,
    medium: `Poppins, sans-serif; font-weight: 500;`,
    semibold: `Poppins, sans-serif; font-weight: 600;`,
    bold: `Poppins, sans-serif; font-weight: 700;`,
    extrabold: `Poppins, sans-serif; font-weight: 700;`,
  },
  fontSize: {
    xxxsmall: "font-size: 12px; line-height: 14px; letter-spacing: 0px;",
    xxsmall: "font-size: 14px; line-height: 20px; letter-spacing: 0px;",
    xsmall: "font-size: 16px; line-height: 24px; letter-spacing: 0px;",
    small: "font-size: 20px; line-height: 24px; letter-spacing: 0px;",
    regular: "font-size: 22px; line-height: 22px; letter-spacing: 0px;",
    medium: "font-size: 28px; line-height: 28px; letter-spacing: 0px;",
    large: "font-size: 32px; line- height: 32px;",
    larger: "font-size: 40px; line-height: 50px; letter-spacing: 0px;",
    xlarge: "font-size: 45px; line-height: 48px; letter-spacing: 0px;",
    jumbo: "font-size: 65px; line-height: 68px; letter-spacing: 0px;",
  },

  color: {
    primary: "#0C017A",
    secondary: "#0055D7",
    accent: "#FFCD0A",
    button: "#FFFFFF",
    link: {
      regular: "#000000",
      nav: "#FFFFFF",
    },
    blue: "#0055d7",
    blueLight: "#39F",
    yellow: "#ffcd0a",
    grey: "#323232",
    lightGrey: "#f0f0f0",
    text: {
      main: "#000000",
      onBackground: "#FFFFFF",
      onWhite: "#000000",
    },
    background: {
      white: "#ffffff",
      light: "#DFEEFE",
    },
    white: {
      regular: "#ffffff",
      lessdark: "#faf9f8",
      dark: "#f6f6f6",
      darker: "#eeeeee",
    },
    black: {
      lightest: "#ABA8AF",
      light: "#564F62",
      regular: "#071435",
    },
  },
  screen: {
    xxs: "352px",
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
  spanWidth: {
    container: "padding-left: 50vw; margin-left: -50vw;padding-right: 50vw;margin-right: -50vw;",
    insideContainer:
      "padding-left: calc(50vw - 50%); margin-left: calc(-50vw + 50%); padding-right: calc(50vw - 50%); margin-right: calc(-50vw + 50%);",
  },
  background: {
    gradient: "linear-gradient(to right,#0055D7,#0C017A)!important",
  },
};

export default theme;
